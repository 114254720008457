import React from "react"
import data from "../data/projects-desc.json"
import ParticlesBg from "../particles-bg-2.5.0/src/index.js"
import "./styles/Projects.css"

const ParseImgPath = (imgpath) => {
    if (imgpath === "") {
        return null;
    }
    if (imgpath.includes("youtube")) {
        return <iframe width="560" height="315" src={imgpath} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>;
    }
    return (
        <img src={imgpath}/>
    );
}
const DoesYearHaveShowcased = (year) => {
    for (const project of year["projects"]) {
        if (project.showcased) {
            return true;
        }
    }
    return false;
}

export const Projects = (props) => {
    console.log(document.body.scrollHeight);
    return (
        <div class="main projects-main">
            <h1>Projects</h1>
            <h2 href="#showcased">Showcased:</h2>
            <ShowcasedProjectDiv />
            <h2 href="#allprojects">All Projects:</h2>
            <AllProjectDiv />
            <ParticlesBg type="cobweb" color="random" bg={true} num={300}/> 
        </div>
    );
}

const AllProjectDiv = (props) => {
    return (
        <>
            {data.map(year => (
                <div class="year-section">
                    <h3>{year["year"]}</h3>
                    {year["projects"].map(project => (
                        <div class="project-section">
                            <h5 id={project.id}><a class="project-title-link" href={'#' + project.id}>{project.name}</a></h5>
                            <h4>{project.date}</h4>
                            {project.desc.map(paragraph => (
                                <p class="desc">{paragraph}</p>
                            ))}
                            {project.repo === "" ? null : <p class="repo-link">Github Repository: <a href={project.repo} target="_blank" rel="noreferrer">{project.repo}</a></p>}
                            {ParseImgPath(project.imgpath)}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}


const ShowcasedProjectDiv = (props) => {
    return (
        <>
            {data.map(year => (
                !DoesYearHaveShowcased(year) ? null : 
                    <div class="year-section">
                        {year["projects"].map(project => (
                            !project.showcased ? null :
                                <div class="project-section">
                                    <h5 id={project.id}><a class="project-title-link" href={'#' + project.id}>{project.name}</a></h5>
                                    <h4>{project.date + ", " + year["year"]}</h4>
                                    {project.desc.map(paragraph => (
                                        <p class="desc">{paragraph}</p>
                                    ))}
                                    {project.repo === "" ? null : <p class="repo-link">Github Repository: <a href={project.repo} target="_blank" rel="noreferrer">{project.repo}</a></p>}
                                    {ParseImgPath(project.imgpath)}
                            </div>
                        ))}
                    </div>
            ))}
        </>
    );
}