// #region imports
import React from "react"
import { Certs } from "./Certs"
import portrait from "../img/meforest2.png"
import aplus from "../img/comptialogos/aplus.png"
import netplus from "../img/comptialogos/netplus.png"
import secplus from "../img/comptialogos/secplus.png"
import ParticlesBg from "../particles-bg-2.5.0/src/index.js"
// import ParticlesBg from "particles-bg"
import "./styles/Home.css"
// #endregion

export const Home = (props) => {
    var style = getComputedStyle(document.body);
    const color = style.getPropertyValue('--shade3');
    return (
        <div className="main">
            <div className="home-main">
                <div className="content">
                    <h1>Hi! I'm Tyler</h1>
                    <img src={portrait}/>
                </div>
            </div>
            <div className="home-desc">
                <h4>Student at <strong><a href="https://www.wgu.edu/" target="_blank" rel="noreferrer">Western Governor's University</a></strong></h4>
                <h4>Studying Cloud Computing</h4>
                <h4>Take a look at my <a href="/projects">projects</a>, <a href="/info">resume</a>, or <a href="/about">learn more about me</a>.</h4>
                <Certs divclass="extra"/>
            </div>
            <ParticlesBg type="cobweb" color="random" bg={true} num={(window.innerWidth/10)+5}/> 
        </div>
    );
};